/* You can add global styles to this file, and also import other style files */
/* purgecss start ignore */
@import "tailwindcss/base";
@import "tailwindcss/components";
/* purgecss end ignore */

@import "tailwindcss/utilities";

/* purgecss start ignore */
html, body {
  @apply h-screen;
}
body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 15px;
  @apply text-gray-dark;
  
  .mat-mdc-dialog-surface {
    padding: 20px 20px 10px 20px;
  }

  .mat-mdc-menu-panel {
    box-shadow: none;
    min-height: 0;
  }

  .mat-mdc-menu-content {
    font-family: 'Source Sans Pro', sans-serif;
  }

  .mat-mdc-paginator {
    font-family: 'Source Sans Pro', sans-serif;
    background: none;
    background: #fff;
  }

  .cdk-overlay-pane {
    overflow-y: auto;
    &.mat-datepicker-popup {
      overflow-y: initial;
    }
  }
  .mat-mdc-snack-bar-container {
    @apply bg-red-700 text-white;
    .mat-mdc-simple-snack-bar {
      justify-content: center;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    .mat-button-wrapper {
      @apply text-white;
    }
  }
  .mat-mdc-menu-item {
    &.menu-item-small {
      min-height: 32px;
      .mdc-list-item__primary-text {
        width: calc(100% - 24px);
      }
    }
    &.mat-mdc-menu-item-link {
      @apply text-primary-blue;
      font-size: 0.8rem;
    }
  }

  .mat-mdc-menu-panel.mat-menu-small {
    width: 210px;
    font-family: 'Source Sans Pro', sans-serif!important;
    @apply border border-gray-300;
  }
}

h2 {
  font-size: 1.6rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 1rem;
}
a {
  @apply text-blue-600;
  cursor: pointer;
  .disabled {
    cursor: default!important;
  }
  &:not(.disabled):hover {
    @apply text-blue-900;
  }
}
ol {
  list-style: decimal;
  list-style-position: inside;
}
input[type=text] {
  &::placeholder {
    @apply italic font-light;
  }
}

.cdk-dropdown {
  @apply rounded rounded-t-none border border-gray-200 border-t-0 text-sm;
  @apply bg-white rounded-b;
  @apply border-indigo-200;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}

.horizontal-scrollbar {
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}


.base-text-input {
  @apply border border-gray-300 px-4 py-2 rounded text-sm bg-white appearance-none mt-1;
  &:focus {
    @apply outline-none;
  }
  &.ng-dirty:invalid, &.ng-dirty.ng-invalid {
    @apply border-red-200;
  }
}

.base-input-label {
  @apply inline-flex flex-col;
}
.cdk-global-scrollblock {
  body {
    overflow: initial;
  }
}


.row-input {
  @apply border border-gray-400 rounded p-1 bg-gray-lighter text-center;
  -moz-appearance: textfield;
  &[disabled] {
    opacity: 0.2;
  }
}
#timesheet-workers-list {
  
  app-dropdown {
    display: inline-flex;
    width: 250px;
  }
  .dropdown-input {
    margin-top: 0;
    width: 250px;
  }
  .dropdown-list-container {
    width: 250px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.base-app-button {
  @apply rounded px-6 py-2;
}

.primary-button {
  @extend .base-app-button;
  @apply bg-blue-700 text-white duration-300 transition-all;
  &:hover {
    @apply bg-blue-800;
  }
  &:disabled {
    @apply opacity-25;
    cursor: default;
  }
}

.secondary-button {
  @extend .base-app-button;
  @apply border-2 text-blue-600 border-blue-600 duration-200 transition;
  &:hover {
    @apply border-blue-700 text-blue-700;
  }
  &:disabled {
    @apply opacity-25;
    cursor: default;
  }
}

.basic-button {
  @apply text-blue-600;
  cursor: pointer;
  &.disabled {
    cursor: default!important;
    @apply text-blue-400 opacity-50;
  }
  &:not(.disabled):hover {
    @apply text-blue-900;
  }
}

.warning-button {
  @extend .base-app-button;
  @apply text-white transition-colors duration-150 bg-red-700;
  &:hover {
    @apply bg-red-800;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-inner-container {
  padding: 0.6rem;
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-frame {
    border-width: 1px;
    border-radius: 3px;
    border-color: #ACB0BD;
  }
}

.list-color-blue {
  font-size: 1.1rem;
  li {
    @apply mb-4;
  }
  li:before {
    content: '\2022';
    display: inline-block;
    width: 1rem;
    margin-left: -1rem;
    @apply text-blue-700;
    font-size: 1.3rem;
  }
}

/// SNACKBAR CLASS ///
.green-snackbar {
  --mdc-snackbar-container-color: #00b100!important;
} 

.flashing-text{
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    opacity: .5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .5;
  }
}
.table-separator {
  height: 6px;
}

/* DRAGGING */
.cdk-drag-preview {
  background: white;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

}

/* Rules for sizing the icon. */
.material-icons.md-16 {
  font-size: 16px;
  width: 16px;
  height: 16px;
}
.material-icons.md-18 {
  font-size: 18px;
  width: 18px;
  height: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
  width: 24px;
  height: 24px;
}
.material-icons.md-30 {
  font-size: 30px;
  width: 30px;
  height: 30px;
}
.material-icons.md-36 {
  font-size: 36px;
  width: 36px;
  height: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
  width: 48px;
  height: 48px;
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

.material-icons.yellow { color: #F7EC12 }
.material-icons.red { color: red; }
.material-icons.blue { color: blue; }

@media print {
  /* invert the display (show/hide) properties of the main */
  /* aplication component and the printing component       */
  @page {
    margin: 0;
    size: landscape;
  }
  body {
    margin: 1.2cm;
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important;  /*Firefox*/
  }

  app-main-page {
    display: none;
  }
}

.bg-blue-shade {
  background-color: #81C4EF;
}

.bg-green-shade {
  background-color: #98EBB1;
}
/* purgecss end ignore */

textarea {
  min-height: 100px;
  max-height: 300px;
  &:focus {
    outline: none;
  }
}

.link-disabled {
  pointer-events: none;
  cursor: default;
}

.mat-mdc-tooltip-info {
  white-space: pre-line;
  font-size: 13px!important;
}

.icon-action {
  opacity: 0.79;
  color: #acb0bd;
  &:disabled {
    cursor: default;
  }
}

.cdk-call {
  background-color: white;
}